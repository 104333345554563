div.ModalContainer{
    /* border: 2px solid red; */
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */

    div.Header{
        /* border: 1px solid blue; */
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
    }

    div.CartProducts{
        /* border: 1px solid green; */
        margin: auto;
        width: 90%;
        height: 80%;
        overflow: auto;
        
        display: flex;
        flex-wrap: wrap;

        div.ItemContainer{
            /* border: 4px solid red; */
            width: 10rem;
            height: 15rem;
            flex: 0 1 50%;
            cursor: pointer;

            div.ItemImage{
                /* border: 4px solid purple; */
                margin: auto;
                max-width: 50%;
                position: relative;

                div.PriceCircle{
                    position: absolute;
                    top: 5%;
                    right: 5%;
                    width: 40%;
                    height: 40%;
                    border-radius: 50%;
                    color: #FFF;
                    font-family: 'Heebo';

                    div.Curr{
                        position: absolute;
                        top: 55%;
                        right: 70%; 
                        font-size: .8rem;

                    }

                    div.Shekels{
                        position: absolute;
                        font-size: 2rem;
                        top: 25%;
                        right: 25%; 
                    }

                    div.Agorot{
                        position: absolute;
                        font-size: .9rem;
                        top: 28%;
                        right: 3%; 
                    }
                }
            }

            div.ItemName{
                /* border: 1px solid red; */
                margin: auto;
                text-align: center;
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.1rem;
                font-weight: 500;
            }
        }

        div.ItemContainer:hover{
            opacity: .8;
        }
    }

    div.NoThanksFooter{
        /* border: 1px solid green; */
        display: flex;
        justify-content: center;

        span{
            /* border: 1px solid red; */
            font-size: 1.8rem;
            color: #7d7d7d;
            text-decoration: underline;
            cursor: pointer;
        }
        span:hover{
            opacity: .7;
        }
    }

}